import { getAnimVersion } from './viewport'

export const ANIM_DURATION = 420
export const funkyEase = "cubic-bezier(.42,-0.42,.15,1.5)"

function shoudDismiss(mouseX, mouseY) {
    let DISMISS_OFFSETS = {
        wide: 630,
        medium: 510,
        small: 480
    }
    let offset = DISMISS_OFFSETS[getAnimVersion()]

    let viewportWidth = window.innerWidth
    let viewportHeight = window.innerHeight

    let viewportCenterX = viewportWidth / 2
    let viewportCenterY = viewportHeight / 2

    let dx = Math.abs(mouseX - viewportCenterX)
    let dy = Math.abs(mouseY - viewportCenterY)

    return dx > offset || dy > offset / 2
}

export function listenForDismiss(callback) {
    function onMouseMove(event) {
        if (shoudDismiss(event.pageX, event.pageY)) {
            document.removeEventListener("mousemove", onMouseMove)

            callback()
        }
    }

    return function() {
        document.addEventListener("mousemove", onMouseMove, {
            passive: true
        })
    }
}

function runTildaAnimations(element) {
    let transitionArr

    try {
        transitionArr = eval(element.dataset.animateSbsOpts)
    } catch (error) {
        throw new Error("Failed to parse Tilda animations: " + error.message)
    }

    if (!Array.isArray(transitionArr)) {
        throw new Error("No transition array given")
    }

    let lastDuration = transitionArr[transitionArr.length - 1].ti
    let keyframes = transitionArr.map(function(tr) {
        return {
            transform: `translate(${tr.mx}px, ${tr.my}px) scale(${tr.sx},${tr.sy})`,
            opacity: tr.op
        }
    })

    return element.animate(keyframes, {
        duration: lastDuration,
        fill: "forwards",
        iterations: 1
    })
}

export function animate(element, keyframes, options) {
    if (keyframes) {
        return element.animate(keyframes, options)
    }

    return runTildaAnimations(element)
}

export function animateStates(element, states, direction = 1) {
    const startKey = direction > 0 ? 0 : 1
    const endKey = direction > 0 ? 1 : 0
    const startState = states[startKey]
    const endState = states[endKey]

    return element.animate({
        transform: [
            `translate(${startState.x}px,${startState.y}px) scale(${startState.scale}) rotate(${startState.rotation}deg)`,
            `translate(${endState.x}px,${endState.y}px) scale(${endState.scale}) rotate(${endState.rotation}deg)`
        ],
        opacity: [startState.opacity, endState.opacity]
    }, { duration: ANIM_DURATION, easing: funkyEase, fill: "forwards" })
}

export function resetAnimation(animation) {
    animation.currentTime = 0
    animation.pause()
}
