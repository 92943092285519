import { getAnimVersion } from "./viewport"

const animationStates = {
    Orthodoxy: {
        wide: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: -60, y: 0, scale: 1, rotation: 0, opacity: 0 }
        ],
        medium: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: -45, y: 0, scale: 1, rotation: 0, opacity: 0 }
        ],
        small: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: -30, y: 0, scale: 1, rotation: 0, opacity: 0 }
        ]
    },
    Catholics: {
        wide: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: 60, y: 0, scale: 1, rotation: 0, opacity: 0 }
        ],
        medium: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: 45, y: 0, scale: 1, rotation: 0, opacity: 0 }
        ],
        small: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: 30, y: 0, scale: 1, rotation: 0, opacity: 0 }
        ]
    },
    pencilLivan: {
        wide: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: 0, y: 30, scale: 1, rotation: 0, opacity: 0 }
        ],
        medium: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: 0, y: 30, scale: 1, rotation: 0, opacity: 0 }
        ],
        small: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: 0, y: 30, scale: 1, rotation: 0, opacity: 0 }
        ]
    },
    pencilInDesign: {
        wide: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: -180, y: -150, scale: 1.8, rotation: 20, opacity: 1 }
        ],
        medium: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: -120, y: -150, scale: 1.8, rotation: 20, opacity: 1 }
        ],
        small: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: 0, y: -150, scale: 1.8, rotation: 20, opacity: 1 }
        ]
    },
    InDesign: {
        wide: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: -240, y: -300, scale: 0.5, rotation: 0, opacity: 1 }
        ],
        medium: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: -230, y: -270, scale: 0.5, rotation: 0, opacity: 1 }
        ],
        small: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: -70, y: -270, scale: 0.5, rotation: 0, opacity: 1 }
        ]
    },
    phoneLivan: {
        wide: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: 0, y: -30, scale: 1, rotation: 0, opacity: 0 }
        ],
        medium: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: 0, y: -30, scale: 1, rotation: 0, opacity: 0 }
        ],
        small: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: 0, y: -30, scale: 1, rotation: 0, opacity: 0 }
        ]
    },
    PhoneInApp: {
        wide: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: -205, y: 180, scale: 2.1, rotation: 0, opacity: 1 }
        ],
        medium: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: -177, y: 175, scale: 2.1, rotation: 0, opacity: 1 }
        ],
        small: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: -85, y: 120, scale: 2.1, rotation: 0, opacity: 1 }
        ]
    },
    appInfoTupan: {
        wide: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 0 },
            { x: 60, y: 0, scale: 1, rotation: 0, opacity: 1 }
        ],
        medium: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 0 },
            { x: 45, y: 0, scale: 1, rotation: 0, opacity: 1 }
        ],
        small: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 0 },
            { x: 30, y: 0, scale: 1, rotation: 0, opacity: 1 }
        ]
    },
    InApp: {
        wide: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: 280, y: -300, scale: 0.5, rotation: 0, opacity: 1 }
        ],
        medium: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: 260, y: -270, scale: 0.5, rotation: 0, opacity: 1 }
        ],
        small: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: 260, y: -270, scale: 0.5, rotation: 0, opacity: 1 }
        ]
    },
    displayInCRM: {
        wide: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: 750, y: 0, scale: 1.2, rotation: 0, opacity: 1 }
        ],
        medium: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: 640, y: 0, scale: 1.2, rotation: 0, opacity: 1 }
        ],
        small: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: 450, y: -40, scale: 1.2, rotation: 0, opacity: 1 }
        ]
    },
    InCRM: {
        wide: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: 200, y: -300, scale: 0.5, rotation: 0, opacity: 1 }
        ],
        medium: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: 150, y: -270, scale: 0.5, rotation: 0, opacity: 1 }
        ],
        small: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 1 },
            { x: 60, y: -270, scale: 0.5, rotation: 0, opacity: 1 }
        ]
    },
    crmInfoTupan: {
        wide: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 0 },
            { x: -60, y: 0, scale: 1, rotation: 0, opacity: 1 }
        ],
        medium: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 0 },
            { x: -45, y: 0, scale: 1, rotation: 0, opacity: 1 }
        ],
        small: [{ x: 0, y: 0, scale: 1, rotation: 0, opacity: 0 },
            { x: -30, y: 0, scale: 1, rotation: 0, opacity: 1 }
        ]
    }

}

export default function getAnimationStates(name) {
    return animationStates[name][getAnimVersion()]
}