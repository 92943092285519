export function getViewportSize() {
    let { clientWidth: width, clientHeight: height } = document.body

    return { width, height }
}

export function getAnimVersion() {
    let { width } = getViewportSize()

    if (width <= 960) {
        return 'small'
    } else if (width > 960 && width <= 1200) {
        return 'medium'
    }

    return 'wide'
}
