import {
    animate,
    animateStates,
    funkyEase,
    resetAnimation,
    listenForDismiss,

    ANIM_DURATION
} from "./animation"
import { getViewportSize } from './viewport'
import getAnimationStates from './animationStates'

document.addEventListener("DOMContentLoaded", function(event) {
    //.42,-0.42,.15,1.5
    // Animation logic
    //.26,-0.13,0,1.3

    let ProductApp = document.querySelector(".Product_App")
    let ProductCRM = document.querySelector(".Product_CRM")
    let ProductDesign = document.querySelector(".Product_Design")

    let productAppCover = document.querySelector("#rec170025822") || document.querySelector("#rec175766050")
    productAppCover.classList.add("Product_App_cover")
    ProductApp.appendChild(productAppCover)

    let productCRMCover = document.querySelector("#rec170032752") || document.querySelector("#rec175766051")
    productCRMCover.classList.add("Product_CRM_cover")
    ProductCRM.appendChild(productCRMCover)

    let productDesignCover = document.querySelector("#rec174476168") || document.querySelector("#rec175766052")
    productDesignCover.classList.add("Product_Design_cover")
    ProductDesign.appendChild(productDesignCover)

    let appDevice = document.querySelector('[data-elem-id="1584099893523"]')
    let appInfo = document.querySelector('[data-elem-id="1584110491464"]')

    let design = document.querySelector('[data-elem-id="1585459254529"]')
    let designInfo = document.querySelector('[data-elem-id="1585459168408"]')

    let crmDevice = document.querySelector('[data-elem-id="1584113482970"]')
    let crmInfo = document.querySelector('[data-elem-id="1584112064338"]')

    let phoneEl = document.querySelector('[data-elem-id="1584091461500"]')
    let slashEl = document.querySelector('[data-elem-id="1584126450964"]')
    let slash2El = document.querySelector('[data-elem-id="1585459254538"]')

    let pencil = document.querySelector('[data-elem-id="1585459646321"]')
    let displayEl = document.querySelector('[data-elem-id="1584094848865"]')
    let logoEl = document.querySelector('[data-elem-id="1584128535591"] > div > img')


    let phoneChildContainer = phoneEl.children[0]
    let phoneLogo = logoEl.cloneNode(true)
    phoneChildContainer.appendChild(phoneLogo)
    phoneChildContainer.style.position = "relative"
    phoneLogo.style.position = "absolute"
    phoneLogo.style.top = "32.7%"
    phoneLogo.style.opacity = 0
    phoneLogo.style.transform = "scale(0.5)"

    let displayChildContainer = displayEl.children[0]
    let displaylogo = logoEl.cloneNode(true)
    displayChildContainer.appendChild(displaylogo)
    displayChildContainer.style.position = "relative"
    displaylogo.style.position = "absolute"
    displaylogo.style.top = "-71.5%"
    displaylogo.style.left = "-28.5%"
    displaylogo.style.opacity = 0
    displaylogo.style.transform = "scale(0.12)"

    //
    // Tease animation
    //
    setTimeout(function() {
        let TEASE_ANIM_DURATION = 600
        let TEASE_ANIM_DELAY_STEP = 300

        appDevice.animate({
            transform: [
                "translateY(0px)",
                "translateY(-20px)",
                "translateY(0px)"
            ]
        }, {
            duration: TEASE_ANIM_DURATION,
            delay: TEASE_ANIM_DELAY_STEP * 0,
            easing: "cubic-bezier(0,.5,1,.5)"
        })

        crmDevice.animate({
            transform: [
                "translateY(0px)",
                "translateY(-20px)",
                "translateY(0px)"
            ]
        }, {
            duration: TEASE_ANIM_DURATION,
            delay: TEASE_ANIM_DELAY_STEP * 1,
            easing: "cubic-bezier(0,.5,1,.5)"
        })

        design.animate({
            transform: [
                "translateY(0px)",
                "translateY(-20px)",
                "translateY(0px)"
            ]
        }, {
            duration: TEASE_ANIM_DURATION,
            delay: TEASE_ANIM_DELAY_STEP * 2,
            easing: "cubic-bezier(0,.5,1,.5)"
        }).onfinish = listenToHovers
    }, 2400)



    function listenToHovers() {
        if (getViewportSize().width > 460) {
            appDevice.addEventListener("mouseenter", onAppHover, { once: true })
            crmDevice.addEventListener("mouseenter", onCrmHover, { once: true })
            design.addEventListener("mouseenter", onDesignHover, { once: true })
        }
    }

    function stopListeningToHovers() {
        appDevice.removeEventListener("mouseenter", onAppHover)
        crmDevice.removeEventListener("mouseenter", onCrmHover)
        design.removeEventListener("mouseenter", onDesignHover)
    }

    function onAppHover() {
        stopListeningToHovers()

        ProductApp.style.display = "block"
        ProductCRM.style.display = "none"
        ProductDesign.style.display = "none"
        appInfo.style.zIndex = 5
        crmInfo.style.zIndex = 0
        designInfo.style.zIndex = 0

        phoneEl.style.zIndex = 100

        animateStates(phoneEl, getAnimationStates('PhoneInApp'))
        phoneLogo.animate({ transform: ["scale(0.5)", "scale(0.42)"], opacity: [0, 1] }, { duration: ANIM_DURATION, easing: funkyEase, fill: "forwards" })

        animateStates(appDevice, getAnimationStates('InApp'))

        animateStates(appInfo, getAnimationStates('appInfoTupan'))

        animateStates(slashEl, getAnimationStates('Catholics'))

        animateStates(slash2El, getAnimationStates('Catholics'))

        animateStates(crmDevice, getAnimationStates('Catholics'))

        animateStates(design, getAnimationStates('Catholics'))

        animateStates(pencil, getAnimationStates('pencilLivan')).onfinish = listenForDismiss(onAppLeave)

        //phoneLogo.animate({ opacity: [0, 1] }, { duration: ANIM_DURATION, easing: funkyEase, fill: "forwards" })
    }

    function onAppLeave() {
        //crmDevice.style.zIndex = 100

        phoneLogo.animate({ transform: ["scale(0.42)", "scale(0.5)"], opacity: [1, 0] }, { duration: ANIM_DURATION, easing: funkyEase, fill: "forwards" })

        animateStates(phoneEl, getAnimationStates('PhoneInApp'), -1)

        animateStates(appDevice, getAnimationStates('InApp'), -1)

        animateStates(appInfo, getAnimationStates('appInfoTupan'), -1)

        animateStates(slashEl, getAnimationStates('Catholics'), -1)

        animateStates(slash2El, getAnimationStates('Catholics'), -1)

        animateStates(crmDevice, getAnimationStates('Catholics'), -1)

        animateStates(design, getAnimationStates('Catholics'), -1)

        animateStates(pencil, getAnimationStates('phoneLivan'), -1).onfinish = listenToHovers
    }

    function onCrmHover() {
        stopListeningToHovers()

        ProductApp.style.display = "none"
        ProductCRM.style.display = "block"
        ProductDesign.style.display = "none"
        appInfo.style.zIndex = 0
        crmInfo.style.zIndex = 5
        designInfo.style.zIndex = 0

        displayEl.style.zIndex = 99

        displaylogo.animate({ transform: ["scale(0.15)", "scale(0.12)"], opacity: [0, 1] }, { duration: ANIM_DURATION, easing: funkyEase, fill: "forwards" })

        animateStates(displayEl, getAnimationStates('displayInCRM'))

        animateStates(phoneEl, getAnimationStates('phoneLivan'))

        animateStates(appDevice, getAnimationStates('Orthodoxy'))

        animateStates(crmInfo, getAnimationStates('crmInfoTupan'))

        animateStates(slashEl, getAnimationStates('Orthodoxy'))

        animateStates(slash2El, getAnimationStates('Catholics'))

        animateStates(crmDevice, getAnimationStates('InCRM'))

        animateStates(design, getAnimationStates('Catholics'))

        animateStates(pencil, getAnimationStates('pencilLivan')).onfinish = listenForDismiss(onCrmLeave)
    }

    function onCrmLeave() {
        appDevice.style.zIndex = 100
        phoneEl.style.zIndex = 100

        displaylogo.animate({ transform: ["scale(0.12)", "scale(0.15)"], opacity: [1, 0] }, { duration: ANIM_DURATION, easing: funkyEase, fill: "forwards" })

        animateStates(phoneEl, getAnimationStates('phoneLivan'), -1)

        animateStates(appDevice, getAnimationStates('Orthodoxy'), -1)

        animateStates(crmInfo, getAnimationStates('crmInfoTupan'), -1)

        animateStates(slashEl, getAnimationStates('Orthodoxy'), -1)

        animateStates(slash2El, getAnimationStates('Catholics'), -1)

        animateStates(crmDevice, getAnimationStates('InCRM'), -1)

        animateStates(displayEl, getAnimationStates('displayInCRM'), -1)

        animateStates(design, getAnimationStates('Catholics'), -1).onfinish = function() { displayEl.style.zIndex = 1 }

        animateStates(pencil, getAnimationStates('pencilLivan'), -1).onfinish = listenToHovers
    }

    function onDesignHover() {
        stopListeningToHovers()

        ProductApp.style.display = "none"
        ProductCRM.style.display = "none"
        ProductDesign.style.display = "block"
        appInfo.style.zIndex = 0
        crmInfo.style.zIndex = 0
        designInfo.style.zIndex = 5
        appDevice.style.zIndex = 0


        animateStates(phoneEl, getAnimationStates('phoneLivan'))

        animateStates(appDevice, getAnimationStates('Catholics'))

        animateStates(crmDevice, getAnimationStates('Catholics'))

        animateStates(slashEl, getAnimationStates('Catholics'))

        animateStates(slash2El, getAnimationStates('Catholics'))

        animateStates(pencil, getAnimationStates('pencilInDesign'))

        animateStates(design, getAnimationStates('InDesign'))

        animateStates(designInfo, getAnimationStates('Catholics'), -1).onfinish = listenForDismiss(onDesignLeave)
    }

    function onDesignLeave() {

        animateStates(phoneEl, getAnimationStates('phoneLivan'), -1)

        animateStates(appDevice, getAnimationStates('Catholics'), -1)

        animateStates(crmDevice, getAnimationStates('Catholics'), -1)

        animateStates(slashEl, getAnimationStates('Catholics'), -1)

        animateStates(slash2El, getAnimationStates('Catholics'), -1)

        animateStates(pencil, getAnimationStates('pencilInDesign'), -1)

        animateStates(design, getAnimationStates('InDesign'), -1).onfinish = function() { appDevice.style.zIndex = 100 }

        animateStates(designInfo, getAnimationStates('Catholics')).onfinish = listenToHovers

    }

})