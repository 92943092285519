function initialize(blocks, { listBlock, infoBlockPlaceSelector }) {
    let infoBlockPlace = document.querySelector(infoBlockPlaceSelector);
    let infoBlockContent = document.createElement("div");

    infoBlockPlace.innerHTML = "";
    infoBlockPlace.appendChild(infoBlockContent);

    blocks.forEach(function(block) {
        let artboardEl = document
            .querySelector(`[data-elem-id="${block.blockId}"]`)
            .closest(".r.t-rec");

        if (artboardEl) {
            artboardEl.style.display = "none";
        }
    });

    function setCurrent(block) {
        let blockContent = document
            .querySelector(`[data-elem-id="${block.blockId}"]`)
            .querySelector(".tn-atom");

        infoBlockContent.innerHTML = "";
        infoBlockContent.appendChild(blockContent.cloneNode(true));
        infoBlockPlace.classList.add("active");

        let allLinks = listBlock.querySelectorAll("a");

        for (let i = 0; i < allLinks.length; i++) {
            allLinks[i].classList.remove("active");
        }

        let activeListLink = document.querySelector(
            'a[href="#' + block.name + '"]'
        );

        if (activeListLink) {
            activeListLink.classList.add("active");
        }
    }

    // Set default block
    setCurrent(blocks[0]);

    // Handle clicks
    listBlock.addEventListener("click", function(event) {
        let target = event.target;
        if (event.target.tagName === "A") {
            event.stopPropagation();
            event.preventDefault();
            let blockName = target.getAttribute("href").replace(/\#/, "");
            let currentBlock = blocks.find(function(block) {
                return block.name === blockName;
            });

            if (currentBlock) {
                setCurrent(currentBlock);
            }
        }
    });
}

document.addEventListener("DOMContentLoaded", function(event) {
    //
    // CRM
    //
    initialize(
        [
            { blockId: "1584169723869", name: "dashboard" },
            { blockId: "1585522854779", name: "internal_chat" },
            { blockId: "1585526411098", name: "IT_support" },
            { blockId: "1585526424321", name: "incoming_request" },
            { blockId: "1585526432689", name: "HR" }
        ], {
            listBlock: document.querySelector("#rec173924469") || document.querySelector("#rec175766038"),
            infoBlockPlaceSelector: '[data-elem-id="1584906138271"]',
        }

    );

    //
    // app
    //
    initialize(
        [
            { blockId: "1585525871001", name: "sign_in" },
            { blockId: "1585525882480", name: "menu_page" },
            { blockId: "1585525899724", name: "account_menu" },
            { blockId: "1585525910115", name: "payment_method" },
            { blockId: "1585525925859", name: "partner_page" },
            { blockId: "1585525946779", name: "chat_page" }
        ], {
            listBlock: document.querySelector("#rec172426101") || document.querySelector("#rec175766036"),
            infoBlockPlaceSelector: '[data-elem-id="1585531840499"]'

        }
    );
    //
    // Design 
    //
    initialize(
        [
            { blockId: "1585541534480", name: "brand" },
            { blockId: "1585541872487", name: "advertising" },
            { blockId: "1585542084730", name: "UI_UX" },
            { blockId: "1585542298230", name: "motion" },
            { blockId: "1585542405585", name: "illustration" }
        ], {
            listBlock: document.querySelector("#rec174685256") || document.querySelector("#rec175766040"),
            infoBlockPlaceSelector: '[data-elem-id="1585538910211"]'
        }
    );
});